// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px; /* equivalent to mt: 4 in the original code */
  }
  
  .calendar-dropdowns {
    display: flex;
    gap: 16px; /* equivalent to gap: 2 in the original code */
    margin-bottom: 16px; /* equivalent to mb: 2 in the original code */
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px; /* equivalent to gap: 1 in the original code */
    margin-top: 16px; /* equivalent to mt: 2 in the original code */
  }
  
  .calendar-day-header {
    text-align: center;
    font-weight: bold;
    color: #FFF; /* ensure the day headers are visible */
  }
  
  .dashboard-box {
    /* border: 1px solid grey; */
    padding: 8px; /* equivalent to padding: 1 in the original code */
    text-align: center;
    cursor: pointer;
    width: 90px;
    height: 90px;
    transition: .2s;
    &:hover {
        border: 1px solid #fff;
        transition: .2s;
    }
  }
  
  .calendar-box-inactive {
    background-color: #333; /* Dark grey color for days outside the current month */
    color: #999; /* Light grey text for inactive days */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pages/trading/Calendar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB,EAAE,6CAA6C;EACjE;;EAEA;IACE,aAAa;IACb,SAAS,EAAE,8CAA8C;IACzD,mBAAmB,EAAE,6CAA6C;EACpE;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,QAAQ,EAAE,8CAA8C;IACxD,gBAAgB,EAAE,6CAA6C;EACjE;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,WAAW,EAAE,uCAAuC;EACtD;;EAEA;IACE,4BAA4B;IAC5B,YAAY,EAAE,kDAAkD;IAChE,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,eAAe;IACf;QACI,sBAAsB;QACtB,eAAe;IACnB;EACF;;EAEA;IACE,sBAAsB,EAAE,uDAAuD;IAC/E,WAAW,EAAE,sCAAsC;EACrD","sourcesContent":[".calendar-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 16px; /* equivalent to mt: 4 in the original code */\n  }\n  \n  .calendar-dropdowns {\n    display: flex;\n    gap: 16px; /* equivalent to gap: 2 in the original code */\n    margin-bottom: 16px; /* equivalent to mb: 2 in the original code */\n  }\n  \n  .calendar-grid {\n    display: grid;\n    grid-template-columns: repeat(7, 1fr);\n    gap: 8px; /* equivalent to gap: 1 in the original code */\n    margin-top: 16px; /* equivalent to mt: 2 in the original code */\n  }\n  \n  .calendar-day-header {\n    text-align: center;\n    font-weight: bold;\n    color: #FFF; /* ensure the day headers are visible */\n  }\n  \n  .dashboard-box {\n    /* border: 1px solid grey; */\n    padding: 8px; /* equivalent to padding: 1 in the original code */\n    text-align: center;\n    cursor: pointer;\n    width: 90px;\n    height: 90px;\n    transition: .2s;\n    &:hover {\n        border: 1px solid #fff;\n        transition: .2s;\n    }\n  }\n  \n  .calendar-box-inactive {\n    background-color: #333; /* Dark grey color for days outside the current month */\n    color: #999; /* Light grey text for inactive days */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
