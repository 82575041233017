import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const defaultPumpValues = [
  { pump: "Д", value: 0 },
  { pump: "Ш", value: 0 },
  { pump: "Ж", value: 0 }
];

const HighlightedBox = styled(Paper)(({ theme, highlighted }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.primary,
  backgroundColor: highlighted ? theme.palette.action.hover : theme.palette.background.paper,
}));

function RebirthCalculator() {
  const [pumpValues, setPumpValues] = useState(defaultPumpValues);
  const [highlightedPumps, setHighlightedPumps] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [terminalPresses, setTerminalPresses] = useState({
    terminal1: 0,
    terminal2: 0,
    terminal3: 0
  });

  const getPumpData = (pumpName) => {
    return pumpValues.find((pump) => pump.pump === pumpName);
  };

  const updatePumpValues = (updatedPumps) => {
    setPumpValues(updatedPumps);
    const changedPumps = updatedPumps.filter((pump) => pump.value !== getPumpData(pump.pump).value).map(pump => pump.pump);
    setHighlightedPumps(changedPumps);
    setTimeout(() => setHighlightedPumps([]), 300);
  };

  const pressTerminal1 = () => {
    updatePumpValues(pumpValues.map((pump) => (pump.pump === "Д" || pump.pump === "Ж" ? { ...pump, value: pump.value + 1 } : pump)));
    setTerminalPresses((prev) => ({
      ...prev,
      terminal3: prev.terminal3 + 1
    }));
  };

  const pressTerminal2 = () => {
    updatePumpValues(pumpValues.map((pump) => ({ ...pump, value: pump.value + 1 })));
    setTerminalPresses((prev) => ({
      ...prev,
      terminal1: prev.terminal1 + 1
    }));
  };

  const pressTerminal3 = () => {
    updatePumpValues(pumpValues.map((pump) => (pump.pump === "Д" ? { ...pump, value: pump.value + 1 } : pump)));
    setTerminalPresses((prev) => ({
      ...prev,
      terminal2: prev.terminal2 + 1
    }));
  };

  const resetWaterLevels = () => {
    setPumpValues(defaultPumpValues);
    setTerminalPresses({ terminal1: 0, terminal2: 0, terminal3: 0 });
  };

  const calculateTerminalPresses = () => {
    if (inputValue.length !== 3 || isNaN(inputValue)) {
      alert("Please enter a valid 3-digit number.");
      return;
    }

    const [targetW, targetX, targetA] = inputValue.split("").map(Number);
    let currentA = getPumpData("Д").value;
    let currentW = getPumpData("Ш").value;
    let currentX = getPumpData("Ж").value;

    let terminal1Presses = 0;
    let terminal2Presses = 0;
    let terminal3Presses = 0;

    while (currentA < targetA || currentW < targetW || currentX < targetX) {
      if (currentW < targetW && currentX < targetX && currentA < targetA) {
        terminal1Presses++;
        currentA++;
        currentW++;
        currentX++;
      } else if (currentX < targetX && currentA < targetA) {
        terminal3Presses++;
        currentA++;
        currentX++;
      } else if (currentW < targetW) {
        terminal1Presses++;
        currentW++;
      } else if (currentA < targetA) {
        terminal2Presses++;
        currentA++;
      } else {
        terminal3Presses++;
        currentX++;
      }
    }

    setTerminalPresses({
      terminal1: terminal1Presses,
      terminal2: terminal2Presses,
      terminal3: terminal3Presses
    });

    const updatedPumpValues = pumpValues.map(pump => {
      if (pump.pump === "Д") {
        return { ...pump, value: currentA };
      } else if (pump.pump === "Ш") {
        return { ...pump, value: currentW };
      } else if (pump.pump === "Ж") {
        return { ...pump, value: currentX };
      } else {
        return pump;
      }
    });

    updatePumpValues(updatedPumpValues);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2, maxWidth: '700px', justifyContent: 'center', margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
        Rebirth Calculator
      </Typography>
      <Grid container spacing={2}>
        {pumpValues.map((pump) => (
          <Grid item xs={4} key={pump.pump}>
            <HighlightedBox highlighted={highlightedPumps.includes(pump.pump)}>
              <Typography variant="h6">{pump.pump}</Typography>
              <Typography variant="body1">{pump.value}</Typography>
            </HighlightedBox>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button variant="contained" fullWidth onClick={pressTerminal1}>
              Terminal 1 ({terminalPresses.terminal1})
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" fullWidth onClick={pressTerminal2}>
              Terminal 2 ({terminalPresses.terminal2})
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" fullWidth onClick={pressTerminal3}>
              Terminal 3 ({terminalPresses.terminal3})
            </Button>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          <strong>Terminal 1:</strong> Adds 1 to Ш, Ж, Д
        </Typography>
        <Typography variant="body2">
          <strong>Terminal 2:</strong> Adds 1 to Д
        </Typography>
        <Typography variant="body2">
          <strong>Terminal 3:</strong> Adds 1 to Д, Ж
        </Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <TextField
            fullWidth
            variant="outlined"
            label="Enter 3-digit number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
            if (e.key === 'Enter') {
                calculateTerminalPresses();
            }
    }}
        />
        <Box sx={{ display: 'flex', marginTop: 2, gap: 1 }}>
            <Button variant="contained" color="primary" onClick={calculateTerminalPresses}>
            Calculate Terminal Presses
            </Button>
            <Button variant="outlined" color="secondary" onClick={resetWaterLevels}>
            Reset Water levels
            </Button>
        </Box>
        </Box>
    </Box>
  );
}

export default RebirthCalculator;
