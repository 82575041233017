import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function Calculator() {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [shares, setShares] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [buyCommission, setBuyCommission] = useState("");
  const [sellCommission, setSellCommission] = useState("");
  const [enableCommission, setEnableCommission] = useState(false);
  const [increment, setIncrement] = useState(0.1);
  const [customIncrement, setCustomIncrement] = useState("");
  const [result, setResult] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const handleCalculate = () => {
    const purchase = parseFloat(purchasePrice);
    const shareCount = parseFloat(shares);
    const sell = parseFloat(sellPrice);
    const buyComm = enableCommission ? parseFloat(buyCommission) : 0;
    const sellComm = enableCommission ? parseFloat(sellCommission) : 0;

    if (
      isNaN(purchase) ||
      isNaN(shareCount) ||
      isNaN(sell) ||
      (enableCommission && (isNaN(buyComm) || isNaN(sellComm)))
    ) {
      setResult("Please enter valid numbers for all fields.");
      return;
    }

    const totalCost = purchase * shareCount;
    const totalRevenue = sell * shareCount;
    const buyCommAmount = (buyComm / 100) * totalCost;
    const sellCommAmount = (sellComm / 100) * totalRevenue;
    const dollarChange =
      totalRevenue - totalCost - buyCommAmount - sellCommAmount;
    const percentChange = (dollarChange / totalCost) * 100;

    setResult({
      dollarChange: dollarChange.toFixed(2),
      percentChange: percentChange.toFixed(2),
      totalCost: totalCost.toFixed(2),
      totalRevenue: totalRevenue.toFixed(2),
      buyCommAmount: buyCommAmount.toFixed(2),
      sellCommAmount: sellCommAmount.toFixed(2),
    });

    calculateTableData(purchase, shareCount, sell, buyComm, sellComm);
    setShowTable(true);
  };

  const calculateTableData = useCallback(
    (purchase, shareCount, sell, buyComm, sellComm) => {
      const incrementValue = parseFloat(increment);
      const table = [];
      for (let i = -10; i <= 10; i++) {
        const tickSellPrice = sell + i * incrementValue;
        const tickTotalRevenue = tickSellPrice * shareCount;
        const tickSellCommAmount = (sellComm / 100) * tickTotalRevenue;
        const tickDollarChange =
          tickTotalRevenue -
          purchase * shareCount -
          (buyComm / 100) * purchase * shareCount -
          tickSellCommAmount;
        const tickPercentChange =
          (tickDollarChange / (purchase * shareCount)) * 100;
        table.push({
          tickSellPrice,
          tickDollarChange: tickDollarChange.toFixed(2),
          tickPercentChange: tickPercentChange.toFixed(2),
          isHighlighted: tickSellPrice === sell,
        });
      }
      setTableData(table);
    },
    [increment]
  );

  useEffect(() => {
    if (purchasePrice && shares && sellPrice) {
      const purchase = parseFloat(purchasePrice);
      const shareCount = parseFloat(shares);
      const sell = parseFloat(sellPrice);
      const buyComm = enableCommission ? parseFloat(buyCommission) : 0;
      const sellComm = enableCommission ? parseFloat(sellCommission) : 0;
      calculateTableData(purchase, shareCount, sell, buyComm, sellComm);
    }
  }, [
    increment,
    purchasePrice,
    shares,
    sellPrice,
    buyCommission,
    sellCommission,
    enableCommission,
    calculateTableData,
  ]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCalculate();
    }
  };

  const formatNumber = (number) => {
    return parseFloat(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleCustomIncrementChange = (event) => {
    setCustomIncrement(event.target.value);
  };

  const applyCustomIncrement = () => {
    const customValue = parseFloat(customIncrement);
    if (!isNaN(customValue) && customValue > 0) {
      setIncrement(customValue);
    } else {
      alert("Please enter a valid number for the custom increment.");
    }
  };

  const incrementValues = [0.01, 0.1, 0.5, 1, 5, 10];

  return (
    <Box p={3} display="flex" justifyContent="center">
      <Paper elevation={3} sx={{ padding: 3, maxWidth: 600, width: "100%" }}>
        <Typography variant="h5" gutterBottom>
          Profit/Loss Calculator
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Buy Price"
              variant="outlined"
              value={purchasePrice}
              onChange={(e) => setPurchasePrice(e.target.value)}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Sell Price"
              variant="outlined"
              value={sellPrice}
              onChange={(e) => setSellPrice(e.target.value)}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="# of Shares"
              variant="outlined"
              value={shares}
              onChange={(e) => setShares(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableCommission}
                  onChange={(e) => setEnableCommission(e.target.checked)}
                  color="primary"
                />
              }
              label="Commission"
            />
          </Grid>
          {enableCommission && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Buy Commission"
                  variant="outlined"
                  value={buyCommission}
                  onChange={(e) => setBuyCommission(e.target.value)}
                  InputProps={{
                    endAdornment: <span>%</span>,
                  }}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Sell Commission"
                  variant="outlined"
                  value={sellCommission}
                  onChange={(e) => setSellCommission(e.target.value)}
                  InputProps={{
                    endAdornment: <span>%</span>,
                  }}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography gutterBottom>Increment Value</Typography>
            <Box display="flex" justifyContent="space-between">
              {incrementValues.map((value) => (
                <Button
                  key={value}
                  variant={increment === value ? "contained" : "outlined"}
                  onClick={() => setIncrement(value)}
                >
                  ${value}
                </Button>
              ))}
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
              <TextField
                label="Custom Increment"
                variant="outlined"
                value={customIncrement}
                onChange={handleCustomIncrementChange}
                onKeyPress={handleKeyPress}
                sx={{ marginRight: 2 }}
              />
              <Button variant="contained" onClick={applyCustomIncrement}>
                Apply
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCalculate}
            >
              Calculate
            </Button>
          </Grid>
        </Grid>
        {result && (
          <Box mt={3}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              {typeof result === "string" ? (
                <Typography color="error">{result}</Typography>
              ) : (
                <>
                  <Typography variant="h6">
                    (${formatNumber(result.totalRevenue)} - $
                    {formatNumber(result.sellCommAmount)} - ($
                    {formatNumber(result.totalCost)} + $
                    {formatNumber(result.buyCommAmount)}) =
                    <Typography
                      component="span"
                      variant="h6"
                      color={
                        result.dollarChange >= 0 ? "success.main" : "error.main"
                      }
                    >
                      {" "}
                      ${formatNumber(result.dollarChange)}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h6"
                    color={
                      result.dollarChange >= 0 ? "success.main" : "error.main"
                    }
                  >
                    {result.percentChange}% ROI
                  </Typography>
                </>
              )}
            </Paper>
          </Box>
        )}
        {showTable && tableData.length > 0 && (
          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sell Price</TableCell>
                    <TableCell>Dollar Change ($)</TableCell>
                    <TableCell>Percent Change (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: row.isHighlighted
                          ? "#434343"
                          : "transparent",
                      }}
                    >
                      <TableCell>${formatNumber(row.tickSellPrice)}</TableCell>
                      <TableCell
                        sx={{
                          color: row.tickDollarChange >= 0 ? "green" : "red",
                        }}
                      >
                        ${formatNumber(row.tickDollarChange)}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: row.tickDollarChange >= 0 ? "green" : "red",
                        }}
                      >
                        {formatNumber(row.tickPercentChange)}%
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
