import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ComputerIcon from '@mui/icons-material/Computer';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import StorageIcon from '@mui/icons-material/Storage';

export default function Home() {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              System Status
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <ComputerIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="body1">CPU Usage: 45%</Typography>
                <Typography variant="body1">Memory Usage: 65%</Typography>
                <Typography variant="body1">Disk Usage: 70%</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              Network Traffic
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <NetworkCheckIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="body1">Upload: 15 Mbps</Typography>
                <Typography variant="body1">Download: 50 Mbps</Typography>
                <Typography variant="body1">Latency: 20 ms</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              Storage Usage
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <StorageIcon sx={{ fontSize: 40, marginRight: 2 }} />
              <Box>
                <Typography variant="body1">Total: 2 TB</Typography>
                <Typography variant="body1">Used: 1.4 TB</Typography>
                <Typography variant="body1">Free: 600 GB</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
