import React, { useState, useEffect, useCallback } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  addDays,
} from "date-fns";
import {
  Box,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import "./Calendar.css";
import { differenceInCalendarDays, endOfWeek } from "date-fns";
import "./Calendar.css";

const years = Array.from({ length: 101 }, (_, i) => i + 2000); // Years from 2000 to 2100
const months = Array.from({ length: 12 }, (_, i) => i); // Months from 0 (January) to 11 (December)
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Calendar() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pnlData, setPnLData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDateKey, setCurrentDateKey] = useState("");
  const [currentPnL, setCurrentPnL] = useState("");
  const [monthlyPnl, setMonthlyPnL] = useState(0);

  const calculateMonthlyPnL = useCallback(
    (data) => {
      const start = startOfMonth(selectedDate);
      const end = endOfMonth(selectedDate);
      const daysInMonth = eachDayOfInterval({ start, end });

      const pnlForMonth = daysInMonth.reduce((total, day) => {
        const dateKey = format(day, "yyyy-MM-dd");
        return total + (data[dateKey] || 0);
      }, 0);
      setMonthlyPnL(pnlForMonth);
    },
    [selectedDate]
  );

  useEffect(() => {
    const fetchPnLData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/tradingPnLData`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const formattedData = data.reduce((acc, item) => {
          acc[item.date] = item.value;
          return acc;
        }, {});
        setPnLData(formattedData);
        calculateMonthlyPnL(formattedData);
      } catch (error) {
        console.error("Error fetching PnL data:", error);
      }
    };

    fetchPnLData();
  }, [calculateMonthlyPnL]);

  useEffect(() => {
    calculateMonthlyPnL(pnlData);
  }, [selectedDate, pnlData, calculateMonthlyPnL]);

  const getPnlColor = (pnl) => {
    if (pnl > 0) {
      return "#4caf50"; // Muted green for positive
    } else if (pnl < 0) {
      return "#f44336"; // Muted red for negative
    } else {
      return "#6A6A6A"; // Light gray for neutral
    }
  };

  const formatPnL = (pnl) => {
    if (pnl) {
      const absPnl = Math.abs(pnl).toLocaleString();
      return pnl < 0 ? `-$${absPnl}` : `$${absPnl}`;
    } else {
      return 0;
    }
  };

  const handleBoxClick = (dateKey) => {
    setCurrentDateKey(dateKey);
    setCurrentPnL(pnlData[dateKey] ?? "");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSavePnL = async () => {
    const updatedPnLData = {
      ...pnlData,
      [currentDateKey]: parseFloat(currentPnL),
    };
    try {
      const response = await fetch(
        `${API_BASE_URL}/tradingPnLData/${currentDateKey}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ value: parseFloat(currentPnL) }),
        }
      );
      if (response.ok) {
        setPnLData(updatedPnLData);
        calculateMonthlyPnL(updatedPnLData);
        setOpenDialog(false);
      } else {
        console.error("Error saving PnL data:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving PnL data:", error);
    }
  };

  const handleYearChange = (event) => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(event.target.value);
    setSelectedDate(newDate);
  };

  const handleMonthChange = (event) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(event.target.value);
    setSelectedDate(newDate);
  };

  const handlePrevMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  // Get the start of the week for the first day of the month
  const firstDayOfMonth = startOfMonth(selectedDate);
  const startDay = startOfWeek(firstDayOfMonth);

  // Calculate the total number of days to be displayed
  const endDay = endOfWeek(endOfMonth(selectedDate));
  const totalDays = differenceInCalendarDays(endDay, startDay) + 1;

  // Fill in cells for all days to be displayed in the calendar
  const calendarDays = [];
  for (let i = 0; i < totalDays; i++) {
    const day = addDays(startDay, i);
    calendarDays.push(day);
  }

  return (
    <Box className="calendar-container">
      <Typography variant="h4" gutterBottom>
        Trading PnL Calendar - Monthly PnL:&nbsp;
        <span style={{ color: getPnlColor(monthlyPnl) }}>
          {formatPnL(monthlyPnl)}
        </span>
      </Typography>
      <Box className="calendar-dropdowns">
        <Button onClick={handlePrevMonth}>{"<"}</Button>
        <Select value={selectedDate.getFullYear()} onChange={handleYearChange}>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
        <Select value={selectedDate.getMonth()} onChange={handleMonthChange}>
          {months.map((month) => (
            <MenuItem key={month} value={month}>
              {format(new Date(0, month), "MMMM")}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={handleNextMonth}>{">"}</Button>
      </Box>
      <Box className="calendar-grid">
        {daysOfWeek.map((day) => (
          <Box key={day} className="calendar-day-header">
            <Typography variant="subtitle2">{day}</Typography>
          </Box>
        ))}
        {calendarDays.map((day) => {
          const dateKey = format(day, "yyyy-MM-dd");
          const pnl = pnlData[dateKey] ?? 0;
          const isCurrentMonth = day.getMonth() === selectedDate.getMonth();

          return (
            <Box
              key={dateKey}
              className={`calendar-box ${
                !isCurrentMonth ? "calendar-box-inactive" : ""
              }`}
              onClick={() => handleBoxClick(dateKey)}
              sx={{
                backgroundColor: isCurrentMonth ? getPnlColor(pnl) : "#171717", // Light gray for inactive days
                borderRadius: "4px",
                padding: "8px",
                margin: "2px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Typography variant="subtitle2">
                {day.getDate() === new Date().getDate() &&
                day.getMonth() === new Date().getMonth() &&
                day.getFullYear() === new Date().getFullYear() ? (
                  <Chip label={format(day, "d")} />
                ) : (
                  <div style={{ marginBottom: "15px" }}>{format(day, "d")}</div>
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#000", marginTop: "5px", fontWeight: 700 }} // Default black for text
              >
                {formatPnL(pnl)}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Enter PnL Data</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="PnL"
            type="number"
            fullWidth
            value={currentPnL}
            onChange={(e) => setCurrentPnL(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSavePnL();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSavePnL}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
