import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const scripts = [
  {
    name: 'Script 1',
    description: 'Description for Script 1',
    action: () => {
      alert('Script 1 executed!');
      // Add your script logic here
    },
  },
  {
    name: 'Script 2',
    description: 'Description for Script 2',
    action: () => {
      alert('Script 2 executed!');
      // Add your script logic here
    },
  },
  {
    name: 'Script 3',
    description: 'Description for Script 3',
    action: () => {
      alert('Script 3 executed!');
      // Add your script logic here
    },
  },
  // Add more scripts here
];

export default function Scripts() {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Scripts
      </Typography>
      <Grid container spacing={3}>
        {scripts.map((script, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {script.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {script.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={script.action}>
                  Run Script
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
