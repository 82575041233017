import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const programs = [
  { name: 'Program 1', description: 'Description for Program 1', link: 'https://example.com/program1' },
  { name: 'Program 2', description: 'Description for Program 2', link: 'https://example.com/program2' },
  { name: 'Program 3', description: 'Description for Program 3', link: 'https://example.com/program3' },
  // Add more programs here
];

export default function Programs() {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Programs to Download
      </Typography>
      <Grid container spacing={3}>
        {programs.map((program, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {program.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {program.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" href={program.link} target="_blank">
                  Download
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
