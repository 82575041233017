import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navbar from "./components/Navbar";
import Home from "./components/pages/home/Home";
import Programs from "./components/pages/programs/Programs";
import Scripts from "./components/pages/scripts/Scripts";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import darkTheme from "./components/utils/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import RebirthCalculator from "./components/pages/warzone/RebirthCalculator";
import Login from "./components/pages/login/Login";
import { AuthProvider } from "./components/AuthContext"; // Import AuthProvider
import PrivateRoute from "./components/PrivateRoutes";
import Calendar from "./components/pages/trading/Calendar";
import Calculator from "./components/pages/trading/Calculator";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/scripts" element={<Scripts />} />
            <Route path="/warzone" element={<RebirthCalculator />} />
            <Route path="/trading/calendar" element={<PrivateRoute element={<Calendar />} />} />
            <Route path="/trading/calculator" element={<PrivateRoute element={<Calculator />} />} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);
