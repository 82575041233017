import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { Divider } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [tradingOpen, setTradingOpen] = React.useState(false);
  const { isAuthenticated, logout } = React.useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleTradingClick = () => {
    setTradingOpen(!tradingOpen);
  };

  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const menuItems = [
    { label: "Home", path: "/" },
    {
      label: "Trading",
      subItems: [
        { label: "Calendar", path: "/trading/calendar" },
        { label: "Calculator", path: "/trading/calculator" },
      ],
    },
    { label: "Programs", path: "/programs" },
    { label: "Scripts", path: "/scripts" },
    { label: "Warzone", path: "/warzone" },
  ];

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.subItems ? (
              <>
                <ListItem button onClick={handleTradingClick}>
                  <ListItemText primary={item.label} />
                  {tradingOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={tradingOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem
                        button
                        component={Link}
                        to={subItem.path}
                        selected={isActive(subItem.path)}
                        sx={{ paddingLeft: 4 }}
                        key={subIndex}
                        onClick={handleCloseDrawer}
                      >
                        <ListItemText
                          primary={subItem.label}
                          sx={{
                            fontWeight: isActive(subItem.path)
                              ? "bold"
                              : "normal",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItem
                button
                component={Link}
                to={item.path}
                selected={isActive(item.path)}
                onClick={handleCloseDrawer}
              >
                <ListItemText
                  primary={item.label}
                  sx={{
                    fontWeight: isActive(item.path) ? "bold" : "normal",
                  }}
                />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <ListItem
        button
        onClick={() => {
          if (isAuthenticated) {
            handleLogout();
          }
          handleCloseDrawer();
        }}
        component={Link}
        to={!isAuthenticated && "/login"}
      >
        <ListItemText primary={isAuthenticated ? "Logout" : "Login"} />
      </ListItem>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Eddie's Sandbox
          </Typography>
          {isAuthenticated ? (
            localStorage.getItem("username")
          ) : (
            <Button
              color="inherit"
              onClick={() => {
                handleLogout();
              }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </Box>
  );
}
